import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import resultbg from "../images/resultbg.jpg"
import Img from "gatsby-image"

const OrganisatieOntwikkeling = () => {
  const data = useStaticQuery(graphql`
    query {
        organisatieontwikkeling: file(relativePath: { eq: "organisatieontwikkeling.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
        peoplehelping: file(relativePath: { eq: "people-helping.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title="organisatieontwikkeling met meetbaar resultaat "
        description="Haal het maximale rendement uit teams door het vertrouwen te vergroten en de samenwerking in een organisatie optimaal te stimuleren."
      />
      <div
        style={{
          width: "100vw",
          height: "250px",
          overflow: "hidden",
          backgroundImage: `url(${resultbg})`,
          backgroundSize: "cover",
          backgroundPosition: "50% 60%",
        }}
      >
        {/*<img style={{width: '100vw'}} src={office} alt=""/>*/}
      </div>
      <div className="highlightedText containerPadModified">
        {/*<div className="tiny-header centerText">Breinkracht organisatieadvies op basis van de Zipperr® Methode</div>*/}
        <div className="heading">
          <div>
            <h1 className={"subTitleContent centerText"}>
              Organisatieontwikkeling met meetbaar resultaat
            </h1>
          </div>
        </div>
        <div className="subhead">
          <p className=" afterlife">Drijfveren</p>
        </div>
        <p>
          In tegenstelling tot lange trajecten door andere organisaties hebben
          wij kortdurende trajecten met uitstekend resultaat. Wie bieden geen
          ellenlange trajecten, maar slechts 5 bijeenkomsten waarbij wij kijken
          naar wie iemand is, wat zijn of haar drijfveren zijn, wat iemand kan
          en matchen met de uitkomst tussen de persoon en de organisatie. Ten
          behoeve van de organisatieontwikkeling maar wij kijken vooral ook
          vanuit de wensen en de drijfveren van de mens. .
        </p>
      </div>

      <div className={"containerPad"}>
        <div className={"half block figurealt"}>
          <div className={"imageStackedBox "}>
            <Img
              fluid={data.organisatieontwikkeling.childImageSharp.fluid}
              objectFit="cover"
              // objectPosition="50% 50%"
              alt="Zipper Case"
              style={{
                maxHeight: "45%",
                display: 'block',
                margin: '0 auto',
                zIndex: '34'
              }}
              className={"stacked1 further"}
            />
            <Img
              fluid={data.peoplehelping.childImageSharp.fluid}
              objectFit="cover"
              // objectPosition="50% 50%"
              alt="Zipper Case"
              style={{
                zIndex: '34'
              }}
              className={"stacked2 further2"}
            />
          </div>
        </div>
        <div className={"half block"}>
          <h2>
            Haal het maximale rendement uit teams door het vertrouwen te
            vergroten en de samenwerking in een organisatie optimaal te
            stimuleren.
          </h2>
          <p>
            De Zipperr®Methode helpt organisaties om in 5 bijeenkomsten te komen
            tot duidelijke, concrete antwoorden op de, vaak ingewikkelde
            vraagstukken die er in een organisatie spelen. Antwoorden waarmee de
            deelnemers gelijk aan de slag kunnen zonder verspilling van tijd,
            geld en energie. Al 10 jaar verrassen wij onze opdrachtgevers in de
            publieke sector en het bedrijfsleven met slimme en nieuwe inzichten
            en bieden strategieën voor talentontwikkeling, persoonlijke
            ontwikkeling en teamontwikkeling voor het huidige tijdperk, waarin
            wij de mens bovenal centraal stellen. De Zipperr®Methode richt zich
            op de ontwikkeling van menselijk kapitaal in organisaties.
          </p>
          <p className={"btn-container"}>
            <Link
              to="/traject"
              className="btn primary-lg round expand-on-hover"
            >
              Meer informatie
            </Link>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default OrganisatieOntwikkeling
